@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
    --bg-color: #1f2937;
    --text-color: #f9fafb;
}

.dark h1 {
    color: var(--text-color);
}

.dark .bg-white {
    background-color: var(--bg-color);
}